<template>
  <div class="content_10">
    <div class="content-1">
      3个社区圈子
    </div>
    <div class="content-2">
      3QLAB是实名邀请制的社区平台，其中“3”包含了“三⽣万物”之意，同时平台也以此将⽤户划分为三个原始圈：科研圈，艺术圈和技术圈。
    </div>
    <div class="content-3">
      <div class="block"  v-bind:class="{open:link_1}">
        <div class="title-1">
          <div class="bg"></div>
          <div class="text">科研圈</div>
        </div>
        <div class="title-2">
          由科研精英组成，⽬前⼊驻平台科研学者包含类⽬理学、⼈⽂、社科、⼯学、农学和⽣环等。
        </div>
        <div class="img-1"></div>
      </div>
      <div class="block"  v-bind:class="{open:link_2}">
        <div class="title-1">
          <div class="bg"></div>
          <div class="text">艺术圈</div>
        </div>
        <div class="title-2">
          由艺术创作者与爱好者组成，⽬前⼊驻平台艺术家包含类⽬摄影、绘画、舞蹈、雕刻和活动等。
        </div>
        <div class="img-2"></div>
      </div>
      <div class="block"  v-bind:class="{open:link_3}">
        <div class="title-1">
          <div class="bg"></div>
          <div class="text">技术圈</div>
        </div>
        <div class="title-2">
          由技术⼯程师组成，⽬前⼊驻平台技术⼈员包含类⽬AR、AI、物联⽹、软件、NFT和区块链等。
        </div>
        <div class="img-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false,
      link_3: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 5) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 200)
      setTimeout(() => {
        this.link_2 = true
      }, 800)

      setTimeout(() => {
        this.link_3 = true
      }, 1300)
    }
  }
}
</script>

<style scoped lang="scss">
.content_10 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 1050px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;

  .content-1 {
    width: 100%;
    height: 40px;
    font-size: 28px;
    text-align: center;
    color: #fff;
  }

  .content-2 {
    color: #fff;
    width: 400px;
    font-size: 18px;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .content-3 {
    width: 100%;
    height: 800px;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    .block {
      width: 300px;
      height: 800px;
      opacity: 0;
      transition: 0.3s all;
      .title-1 {
        width: 100%;
        height: 80px;
        position: relative;
        .bg {
          width: 70px;
          height: 70px;
          position: absolute;
          left: 65px;
          top: -20px;
          background-color: #7C5FD7;
          border-radius: 50px;
        }

        .text {
          position: absolute;
          width: 100%;
          font-size: 25px;
          color: #fff;
          text-align: center;
        }
      }
      .title-2{
        //position: absolute;
        //left: 0;
        //top: 100px;
        width: 100%;
        height: 100px;
        color: #A3A3A3;
        text-align: center;
        font-size: 18px;
      }
      @mixin img{
        width: 100%;
        height: 600px;
        margin-top: 10px;
        background-size: 100% 100%;
      }
      .img-1{
       @include img;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-10-1.png");
      }
      .img-2{
        @include img;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-10-2.png");
      }
      .img-3{
        @include img;
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-10-3.png");
      }
    }

    .open{
      opacity: 1;
    }
  }
}
</style>
