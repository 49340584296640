<template>
  <div class="content_2">
    <div class="content_2-1">Merged novels</div>
    <div class="content_2-2" id="book">
      万人添书
      <div class="right-hr"></div>
      <div class="qr">
        活动二维码
        <div class="qrImage">
          <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-2-9/1_667619374_171_85_3_665768421_b0f0935fc4377925ff8bdc6f00c9fcf8.png">
        </div>
      </div>
    </div>
    <div class="content_2-3">
      <div class="left"></div>
      <div class="right">
        <div class="text">
          <p>万⼈添书是⼀场基于交叉学科背景的群体智慧实验，通过区块链技术保障群体写作的版权及利益分配，让“万⼈”共同创作⽂学作品。参与者们围绕同⼀主题，组成团队完成⽂学挑战，以⽂会友，共创添书宇宙。</p>
          <p>尊重创作，尊重阅读，让⼈⼈都能成为创作者。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {}
</script>

<style scoped lang="scss">
.content_2 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 550px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;

  .content_2-1 {
    color: #A3A3A3;
    font-size: 20px;
  }

  .content_2-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;

    .right-hr {
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 170px;
      top: 17px;
    }

    .qr {
      width: 142px;
      height: 40px;
      border: 1px solid #fff;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: -5px;
      cursor: pointer;
      user-select: none;
      .qrImage{
        width: 100px;
        height: 100px;
        background-color: #fff;
        position: absolute;
        top: -110px;
        left: 20px;
        opacity: 0;
        transition: 0.3s all;
        border-radius: 5px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    .qr:hover .qrImage{
      opacity: 1;
    }
  }

  .content_2-3 {
    width: 1200px;
    height: 335px;
    position: absolute;
    left: 0;
    top: 180px;
    .left{
      width: 943px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-2-1.png");
      background-size: 100% 100%;
    }
    .right{
      width:  257px;
      height: 100%;
      background: linear-gradient(180deg, #3A76E5 0%, #805ED7 100%);
      position: absolute;
      right: 0;
      top: 0;
      .text{
        color: #fff;
        width: 220px;
        position: absolute;
        left: 50%;
        margin-left: -110px;
        top: 20px;
        font-size: 14px;
        line-height: 25px;

      }
    }
  }
}
</style>
