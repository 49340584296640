<template>
  <div class="content_5">
    <div class="content-5-1">
      上链存证
      <div class="hr"></div>
    </div>
    <div class="content-5-2">
      平台基于区块链技术来实现每⼀幅作品的上链确权，我们保障每⼀位创作者的创作及所有权益。
    </div>
    <div class="content-5-3">
      <div class="img"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content_5{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 530px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  .content-5-1{
    position: absolute;
    color: #fff;
    left: 0;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 140px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }
  .content-5-2{
    width: 293px;
    position: absolute;
    left: 0;
    top: 60px;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
  }
  .content-5-3{
    width: 219px;
    height: 402px;
    border: solid 4px transparent;
    border-radius: 5px;
    background-image: linear-gradient(#000, #000),
    linear-gradient(180deg, rgba(62, 117, 228, 1), rgba(124, 95, 215, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: absolute;
    right: 250px;
    .img{
      width: 220px;
      height: 430px;
      background-size: 100% 100%;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-8.png");
      position: absolute;
      left: -100px;
      top: 50px;
    }
  }
}
</style>
