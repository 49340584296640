<template>
  <div class="content_7">
    <div class="content-7-1">
      共创画布
      <div class="hr"></div>
    </div>
    <div class="content-7-2">
      <div class="block"  v-bind:class="{open:link_1}">
        <div class="bg"></div>
        <p>坐标体系</p>
        <p>3QLAB团队设计了上万个坐标点供⽤户选择创作独⼀⽆⼆的艺术作品。</p>
      </div>
      <div class="block"  v-bind:class="{open:link_2}">
        <div class="bg"></div>
        <p>画布</p>
        <p>3QLAB团队设计了适⽤于不同⽤户端的电⼦画布，同时模拟传统绘画过程中使⽤的⼯具，还原物理绘画的触感与肌理，最⼤限度满⾜创作者的需求与想象空间。</p>
      </div>
    </div>
    <div class="content-7-3">
      <div class="content-7-3-1"></div>
      <div class="content-7-3-2"></div>
      <div class="content-7-3-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 2) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 200)
      setTimeout(() => {
        this.link_2 = true
      }, 800)
    }
  }
}
</script>

<style scoped lang="scss">
.content_7 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 750px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;

  .content-7-1 {
    position: absolute;
    color: #fff;
    left: 0;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 140px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }

  .content-7-2 {
    width: 100%;
    height: 360px;
    position: absolute;
    left: 0;
    top: 130px;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    justify-content: space-around;


    .block {
      width: 417px;
      position: relative;
      margin-bottom: 80px;
      opacity: 0;
      transition: 0.3s all;

      .bg {
        border: 2px solid #7C5FD7;
        width: 70px;
        height: 70px;
        border-radius: 50px;
        position: absolute;
        left: -50px;
        top: -20px;
      }
    }

    .open{
      opacity: 1;
    }
  }

  .content-7-3 {
    width: 100%;
    height: 300px;
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    position: absolute;
    left: 0;
    top: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @mixin img{
      width: 350px;
      height: 200px;
      background-size: 100% 100%;
    }
    .content-7-3-1{
      @include img;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-7-3-1.png");
    }
    .content-7-3-2{
      @include img;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-7-3-2.png");
    }
    .content-7-3-3{
      @include img;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-7-3-3.png");
    }
  }
}
</style>
