<template>
  <div class="content_3">
    <div class="content-3-1">
      群体智慧
      <div class="hr"></div>
    </div>
    <div class="content-3-2">
      万⼈添书以⼩型群体创作⼯坊为砖⽯，围绕同⼀主线共创精彩故事。
    </div>
    <div class="content-3-3">
      <div class="block" v-bind:class="{open:link_1}">
        <div class="bg"></div>
        <p>限定主题</p>
        <p>万人添书每季限定⼀个主题，⽂体题材不限，诚邀⼤家充分发挥想象⼒与热情创造独⼀⽆⼆的故事。</p>
      </div>
      <div class="block" v-bind:class="{open:link_2}">
        <div class="bg"></div>
        <p>小组群创</p>
        <p>参与⼈⾃由组成3⼈为上限的小队，以接龙形式在规定时限内完成创作挑战，即可获得丰厚奖品。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 2) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 200)
      setTimeout(() => {
        this.link_2 = true
      }, 800)
    }
  }
}
</script>

<style scoped lang="scss">
.content_3 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 450px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;

  .content-3-1 {
    position: absolute;
    color: #fff;
    left: 0;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 160px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }

  .content-3-2 {
    width: 293px;
    position: absolute;
    left: 0;
    top: 60px;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
  }

  .content-3-3 {
    width: 417px;
    height: 360px;
    position: absolute;
    left: 500px;
    top: 60px;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;

    .block {
      opacity: 0;
      transition: 0.3s all;
      position: relative;
      margin-bottom: 80px;

      .bg {
        border: 2px solid #7C5FD7;
        width: 70px;
        height: 70px;
        border-radius: 50px;
        position: absolute;
        left: -50px;
        top: -40px;
      }
    }

    .open {
      opacity: 1;
    }
  }
}
</style>
