<template>
  <div class="content_1">
    <div class="main">
      <div class="title-1">PRODUCTS</div>
      <div class="title-2">产品介绍
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <div class="text">
        3QLAB聚力打造自治化社区组织，涉足科学、艺术、技术三大领域，倡导群体性智慧，呼吁交叉学科碰撞，尊重每位成员的贡献。这里有文学挑战、艺术平台和跨学科社区，这里鼓励参与者成为贡献者，欢迎贡献者成为决策者，3QLAB永远为探索
        家和创新者敞开大门。
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.content_1 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 523px;
  margin: 0 auto;
  background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-1-1.png");
  background-size: 100% 100%;
  position: relative;

  .main {
    width: 480px;
    height: 523px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 50%;
    margin-left: -240px;

    .title-1 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 16px;
      position: absolute;
      top: 40px;
    }

    .title-2 {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      position: absolute;
      top: 80px;
      @mixin hr{
        width: 120px;
        height: 1px;
        position: absolute;
        border: 0;
        padding-top: 1px;
      }
      .left{
        @include hr;
        background: linear-gradient(to right, transparent, #d0d0d5);
        left: 40px;
        top: 20px;
      }
      .right {
        @include hr;
        background: linear-gradient(to left, transparent, #d0d0d5);
        right: 40px;
        top: 20px;
      }
    }
    .text{
      width: 380px;
      height: 200px;
      color: #fff;
      position: absolute;
      left: 55px;
      top: 150px;
      letter-spacing:3px;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
    }
  }
}
</style>