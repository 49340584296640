<template>
  <div class="nav">
    <div class="main">
      <router-link to="/Home"><div class="logo"></div></router-link>
      <div v-if="isMain" class="mainF">
         <div class="block">
          <router-link to="/Home">三驱宇宙</router-link>
        </div>
        <div class="block">
          <router-link to="/Base">三驱基地</router-link>
        </div>
        <div class="block">
          <router-link to="/ChessGame">三一棋局</router-link>
        </div>
        <div class="block">
          <router-link to="/Product">产品介绍</router-link>
        </div>
        <div class="block">
          <router-link to="/TenThousand">万人添X</router-link>
        </div>
        <div class="block">
          <router-link to="/Dac">3QDAC</router-link>
        </div>
      </div>
    </div>
    <div class="hr"></div>
  </div>
</template>

<script>
export default {
  props: {
    isMain: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.nav{
  width: 1200px;
  height: 80px;
  margin: 0 auto;
  position: relative;
  //background-color: #535353;
  .main{
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 40px;
    .logo{
      position: absolute;
      left: 100px;
      top: 10px;
      width: 120px;
      height: 40px;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/3qlab_logo.png");
      background-size: 100% 100%;
    }
    .mainF{
      width: 700px;
      height: 40px;
      position: absolute;
      top: 15px;
      right: 0;
      a{
        text-decoration: none;
        color: #A3A3A3;
        outline: none;
      }
      .block{
        width: 100px;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        color: #919191;
        display: inline-block;
        font-size: 13px;
      }
      .block:hover{
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  .hr {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 0;
    padding-top: 1px;
    background: linear-gradient(to right, transparent, #d0d0d5, transparent);
  }
}
</style>
