<template>
  <div class="Product">
    <Header></Header>
    <content_1></content_1>
    <content_2></content_2>
    <content_3></content_3>
    <content_4></content_4>
    <content_5></content_5>
    <content_6></content_6>
    <content_7></content_7>
    <content_8></content_8>
    <content_9></content_9>
    <content_10></content_10>
    <content_11></content_11>
    <content_12></content_12>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import content_1 from "@/views/Product/com/content_1";
import content_2 from "@/views/Product/com/content_2";
import content_3 from "@/views/Product/com/content_3";
import content_4 from "@/views/Product/com/content_4";
import content_5 from "@/views/Product/com/content_5";
import content_6 from "@/views/Product/com/content_6";
import content_7 from "@/views/Product/com/content_7";
import content_8 from "@/views/Product/com/content_8";
import content_9 from "@/views/Product/com/content_9";
import content_10 from "@/views/Product/com/content_10";
import content_11 from "@/views/Product/com/content_11";
import content_12 from "@/views/Product/com/content_12";

export default {
  components: {
    Header,
    Footer,
    content_1,
    content_2,
    content_3,
    content_4,
    content_5,
    content_6,
    content_7,
    content_8,
    content_9,
    content_10,
    content_11,
    content_12
  },
  mounted() {
    let section = this.$router.currentRoute.hash.replace("#", "");
    if (section) {
      this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());
    }
  },
}
</script>

<style scoped lang="scss">
.Product{
  background-color: #000;
  width: 100%;
}
</style>
