<template>
  <div class="Footer">
    <div class="hr"></div>
    <div class="nav">
      <div class="block">
        <span class="icon dianhua"></span>
        <p>加入我们：hr@3qlab.com</p>
      </div>
      <div class="logo"></div>
      <div class="block">
        <span class="icon email"></span>
        <p>业务合作：contact@3qlab.com</p>
      </div>
    </div>
    <div class="link">
<!--      <h3>友情链接</h3>-->
<!--      <div class="text">-->
<!--        <a>华盛集团</a>-->
<!--      </div>-->
<!--      <div class="text">-->
<!--        <a>盛天传媒</a>-->
<!--      </div>-->
      <div class="end">Copyright @ www.3qlab.com,All Rights Reserved.<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19046295号-1</a></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">

.Footer {
  width: 1200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
  font-family: 'SourceHanSansCN';

  .hr {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    padding-top: 1px;
    background: linear-gradient(to right, transparent, #d0d0d5, transparent);
  }

  .nav {
    width: 1200px;
    height: 60px;
    position: absolute;
    left: 0;
    top: 40px;
    display: flex;
    justify-content: space-around;

    .logo{
      //position: absolute;
      width: 160px;
      height: 60px;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Footer/logo.png");
      background-size: 100% 100%;
      //left: 50%;
      //margin-left: -80px;
    }

    .block {
      color: #fff;
      width: 350px;
      height: 60px;
      font-size: 15px;
      position: relative;

      p {
        position: absolute;
        left: 90px;
        top: 5px;
      }

      .icon {
        width: 60px;
        height: 60px;
        background-size: 100% 100%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
      }

      .dianhua {
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Footer/dianhua.png");
      }

      .email {
        background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Footer/email.png");
      }
    }
  }
  .link{
    color: #fff;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 130px;
    .text{
      color: #A3A3A3;
      margin-top: 15px;
      a{
        cursor: pointer;
        color: #A3A3A3;
        outline: none;
        text-decoration:underline;
      }
    }
    .end{
      color: #A3A3A3;
      margin-top: 15px;
      a{
        text-decoration: none;
        color: #A3A3A3;
        outline: none;
      }
    }
  }
}
</style>
