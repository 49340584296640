<template>
  <div class="content_9">
    <div class="content_6-1">
      3QLAB APP
    </div>
    <div class="content_6-2" id="3qapp">
      3QLAB APP
      <div class="right-hr"></div>
      <div class="qr">
        APP二维码
        <div class="qrImage">
          <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-2-9/1_667619374_171_85_3_665770000_72eb0c6ee29e223063c1ee67bac56b59.png">
        </div>
      </div>
    </div>
    <div class="content_6-3">
      <div class="left">
        <img class="app-logo" src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-9-app.png"/>
      </div>
      <div class="right">
        <div class="text">
          <p>3QLAB APP是由3QLAB团队倾⼒打造，致⼒于为科研学者、艺术创作者、技术⼯程师搭建⼀个集分享、娱乐和共创协作于一体的社区平台；平台基于区块链技术来实现超低成本、可信度极⾼的知识产权确权，从⽽实现对科研、艺术、技术数据进⾏⼤规模、⾼频共享与协作，形成全新的探索与共享社区、研究评价机制。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content_9{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 700px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  .content_6-1 {
    color: #A3A3A3;
    font-size: 20px;
  }

  .content_6-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;

    .right-hr {
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 170px;
      top: 17px;
    }

    .qr {
      width: 142px;
      height: 40px;
      border: 1px solid #fff;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: -5px;
      cursor: pointer;
      user-select: none;

      .qrImage {
        width: 100px;
        height: 100px;
        background-color: #fff;
        position: absolute;
        top: -110px;
        left: 20px;
        opacity: 0;
        transition: 0.3s all;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
      .qr:hover .qrImage {
        opacity: 1;
      }
  }

  .content_6-3 {
    width: 1200px;
    height: 335px;
    position: absolute;
    left: 0;
    top: 180px;
    .left{
      width: 943px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #A3A3A3;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-9.png");
      background-size: 'cover';
      
    }

    .app-logo {
      position: absolute;
      left: 427px;
      top: 100px;
      width: 150px;
    }

    .right{
      width:  257px;
      height: 100%;
      background: linear-gradient(180deg, #3A76E5 0%, #805ED7 100%);
      position: absolute;
      right: 0;
      top: 0;
      .text{
        color: #fff;
        width: 220px;
        position: absolute;
        left: 50%;
        margin-left: -110px;
        top: 20px;
        font-size: 14px;
        line-height: 25px;

      }
    }
  }
}
</style>
