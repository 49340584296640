<template>
  <div class="content_4">
    <div class="content-4-1">
      交叉学科
      <div class="hr"></div>
    </div>
    <div class="content-4-2">
      <div class="block">
        <div class="text">
          <p class="title">鼓励互动</p>
          <p>万⼈添书⿎励组内群创，呼吁组间互动，每⼀季都会召开线上、线下分享会，让创作者们尽情分享、充分探讨、相互学习，在创作路上结识更多志同道合的好友。</p>
        </div>
      </div>
      <div class="block">
        <div class="text">
          <p class="title">不设⻔槛</p>
          <p>万⼈添书⿎励任何⼈参与创作，⽆论你是⽂学⼩⽩还是特定领域的发烧友，⽆论你是作家或是科学、艺术的追梦⼈，⽆论你涉⾜法律界、⾦融界还是着迷于⾃媒体，都可以在这⾥成为创作者。</p>
        </div>
      </div>
      <div class="block">
        <div class="text">
          <p class="title">社群⾃治</p>
          <p>将⾃主权交到每⼀位创作者⼿中，下⼀季的主题、分享会的议题、线下彩蛋的形式，都可以由每⼀位作者发声建议，欢迎每⼀个对群体智慧有兴趣的朋友加入3QLAB。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content_4{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 420px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  .content-4-1 {
    position: absolute;
    color: #fff;
    left: 0;
    font-size: 25px;

    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 160px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }

  .content-4-2{
    width: 100%;
    height: 253px;
    position: absolute;
    left: 0;
    top: 100px;
    display: flex;
    justify-content: space-around;
    .block{
      width: 303px;
      height: 253px;
      border: solid 4px transparent;
      border-radius: 21px;
      background-image: linear-gradient(#000, #000),
      linear-gradient(180deg, rgba(62, 117, 228, 1), rgba(124, 95, 215, 1));
      background-origin: border-box;
      background-clip: content-box, border-box;
      position: relative;
      .text{
        width: 270px;
        position: absolute;
        left: 50%;
        margin-left: -130px;
        top: 0;
        color: #fff;
        line-height: 30px;
        .title{
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
