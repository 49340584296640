<template>
  <div class="content_12">
    <div class="content-1" v-bind:class="{open:link_1}">
      上链存证
      <div class="hr"></div>
    </div>
    <div class="content-2" v-bind:class="{open:link_1}">
      通过区块链底层技术的搭建，⽤户将⾃⼰的成果或作品进⾏⻓⽂发布后即可选择上链存证服务，以保障⽤户知识产权的确权。
    </div>
    <div class="content-3" v-bind:class="{open:link_2}">
      <div class="img"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link_1: false,
      link_2: false
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
  },
  methods: {
    scrollHandle() {
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= (window.innerHeight / 2) && offsetBottom >= (window.innerHeight / 2)) {
        this.init()
      }
    },
    init() {
      setTimeout(() => {
        this.link_1 = true
      }, 200)
      setTimeout(() => {
        this.link_2 = true
      }, 800)
    }
  }
}
</script>

<style scoped lang="scss">
.content_12 {
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 1050px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;

  .content-1 {
    position: absolute;
    color: #fff;
    left: 0;
    font-size: 25px;
    opacity: 0;
    transition: 0.3s all;
    .hr {
      width: 200px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      left: 140px;
      top: 17px;
      background: linear-gradient(to left, transparent, #d0d0d5);
    }
  }

  .content-2 {
    width: 293px;
    position: absolute;
    left: 0;
    top: 60px;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    opacity: 0;
    transition: 0.3s all;
  }

  .content-3 {
    width: 219px;
    height: 402px;
    border: solid 4px transparent;
    border-radius: 5px;
    background-image: linear-gradient(#000, #000),
    linear-gradient(180deg, rgba(62, 117, 228, 1), rgba(124, 95, 215, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: absolute;
    right: 250px;
    opacity: 0;
    transition: 0.3s all;

    .img {
      width: 220px;
      height: 430px;
      background-size: 100% 100%;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-12.png");
      position: absolute;
      left: -100px;
      top: 50px;
    }
  }

  .open{
    opacity: 1;
  }
}
</style>