<template>
<div class="content_6">
  <div class="content_6-1">
    Merged paintings
  </div>
  <div class="content_6-2" id="picture">
    万人添画SaaS
    <div class="right-hr"></div>
    <div class="qr">
      活动二维码
      <div class="qrImage">
        <img src="https://3qlab-wrth.oss-cn-hangzhou.aliyuncs.com/image/2023-2-21/1_410911014_171_85_3_665666271_e5870134c533cde4d226c45753dd50b7.png">
      </div>
    </div>
  </div>
  <div class="content_6-3">
    <div class="left"></div>
    <div class="right">
      <div class="text">
        <p>2021年，艺术与数字技术相碰撞，数字艺术品伴随着区块链技术的成熟应运⽽⽣，3Q团队承势⽽上倾⼒打造万⼈共创的艺术SaaS系统，致⼒于让更多⼈参与到数字艺术的共创热潮中，充分带动每个⼈的参与热情，激发创意与智慧，打造属于这个时代独⼀⽆⼆的共创艺术品。</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.content_6{
  font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
  width: 1200px;
  height: 580px;
  margin: 0 auto;
  background-size: 100% 100%;
  position: relative;
  .content_6-1 {
    color: #A3A3A3;
    font-size: 20px;
  }

  .content_6-2 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    height: 40px;
    position: relative;

    .right-hr {
      width: 1000px;
      height: 1px;
      position: absolute;
      border: 0;
      padding-top: 1px;
      background: linear-gradient(to right, transparent, #d0d0d5);
      right: 170px;
      top: 17px;
    }

    .qr {
      width: 142px;
      height: 40px;
      border: 1px solid #fff;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: -5px;
      cursor: pointer;
      user-select: none;
      .qrImage {
        width: 100px;
        height: 100px;
        background-color: #fff;
        position: absolute;
        top: -110px;
        left: 20px;
        opacity: 0;
        transition: 0.3s all;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .qr:hover .qrImage {
      opacity: 1;
    }
  }

  .content_6-3 {
    width: 1200px;
    height: 335px;
    position: absolute;
    left: 0;
    top: 180px;
    .left{
      width: 943px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #A3A3A3;
      background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Product/content-6-1.jpg");
      background-size: 100% 100%;
    }
    .right{
      width:  257px;
      height: 100%;
      background: linear-gradient(180deg, #3A76E5 0%, #805ED7 100%);
      position: absolute;
      right: 0;
      top: 0;
      .text{
        color: #fff;
        width: 220px;
        position: absolute;
        left: 50%;
        margin-left: -110px;
        top: 20px;
        font-size: 14px;
        line-height: 25px;

      }
    }
  }
}
</style>
